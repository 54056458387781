import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_5 = { class: "flex flex-col gap-4 sm:gap-0" }
const _hoisted_6 = { class: "pt-5" }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_SimpleQuestion = _resolveComponent("SimpleQuestion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('TrackTreatmentResult.Title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('TrackTreatmentResult.PersonalPatientInfo')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Select, {
              text: _ctx.$t('TrackTreatmentResult.adherence'),
              options: [
                _ctx.$t('TrackTreatmentResult.adherence_more_75'),
                _ctx.$t('TrackTreatmentResult.adherence_50_75'),
                _ctx.$t('TrackTreatmentResult.adherence_25_49'),
                _ctx.$t('TrackTreatmentResult.adherence_less_25')
              ],
              modelValue: _ctx.trackTreatmentResult.adherence,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.trackTreatmentResult.adherence) = $event))
            }, null, 8, ["text", "options", "modelValue"]),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.taken_correctly'),
              value: _ctx.trackTreatmentResult.taken_correctly,
              key: _ctx.trackTreatmentResult.taken_correctly,
              onChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.trackTreatmentResult.taken_correctly = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.n_drop'),
              value: _ctx.trackTreatmentResult.n_drop_,
              key: _ctx.trackTreatmentResult.n_drop_,
              onChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.trackTreatmentResult.n_drop_ = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.any_ae'),
              value: _ctx.trackTreatmentResult.any_ae,
              key: _ctx.trackTreatmentResult.any_ae,
              onChanged: _cache[3] || (_cache[3] = ($event: any) => (_ctx.trackTreatmentResult.any_ae = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.n_drop_ae'),
              value: _ctx.trackTreatmentResult.n_drop_ae,
              key: _ctx.trackTreatmentResult.n_drop_ae,
              onChanged: _cache[4] || (_cache[4] = ($event: any) => (_ctx.trackTreatmentResult.n_drop_ae = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.appetite_decreased'),
              value: _ctx.trackTreatmentResult.appetite_decreased,
              key: _ctx.trackTreatmentResult.appetite_decreased,
              onChanged: _cache[5] || (_cache[5] = ($event: any) => (_ctx.trackTreatmentResult.appetite_decreased = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.insomnia'),
              value: _ctx.trackTreatmentResult.insomnia,
              key: _ctx.trackTreatmentResult.insomnia,
              onChanged: _cache[6] || (_cache[6] = ($event: any) => (_ctx.trackTreatmentResult.insomnia = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.somnolence'),
              value: _ctx.trackTreatmentResult.somnolence,
              key: _ctx.trackTreatmentResult.somnolence,
              onChanged: _cache[7] || (_cache[7] = ($event: any) => (_ctx.trackTreatmentResult.somnolence = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.dizziness'),
              value: _ctx.trackTreatmentResult.dizziness,
              key: _ctx.trackTreatmentResult.dizziness,
              onChanged: _cache[8] || (_cache[8] = ($event: any) => (_ctx.trackTreatmentResult.dizziness = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.vomiting'),
              value: _ctx.trackTreatmentResult.vomiting,
              key: _ctx.trackTreatmentResult.vomiting,
              onChanged: _cache[9] || (_cache[9] = ($event: any) => (_ctx.trackTreatmentResult.vomiting = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.dry_mouth'),
              value: _ctx.trackTreatmentResult.dry_mouth,
              key: _ctx.trackTreatmentResult.dry_mouth,
              onChanged: _cache[10] || (_cache[10] = ($event: any) => (_ctx.trackTreatmentResult.dry_mouth = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.tic'),
              value: _ctx.trackTreatmentResult.tic,
              key: _ctx.trackTreatmentResult.tic,
              onChanged: _cache[11] || (_cache[11] = ($event: any) => (_ctx.trackTreatmentResult.tic = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.seizures'),
              value: _ctx.trackTreatmentResult.seizures,
              key: _ctx.trackTreatmentResult.seizures,
              onChanged: _cache[12] || (_cache[12] = ($event: any) => (_ctx.trackTreatmentResult.seizures = $event))
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_SimpleQuestion, {
              text: _ctx.$t('TrackTreatmentResult.vasovagal_syncope'),
              value: _ctx.trackTreatmentResult.vasovagal_syncope,
              key: _ctx.trackTreatmentResult.vasovagal_syncope,
              onChanged: _cache[13] || (_cache[13] = ($event: any) => (_ctx.trackTreatmentResult.vasovagal_syncope = $event))
            }, null, 8, ["text", "value"]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('TrackTreatmentResult.Back')), 1),
          _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('TrackTreatmentResult.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}