
import Patient from '@/models/Patient'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'
import SimpleQuestion from '@/components/SimpleQuestion.vue'
import Select from '@/components/Select.vue'
import Swal from 'sweetalert2'

@Options({
  components: {
    SimpleQuestion,
    Select
  },
  methods: mapActions(['updateTrackTreatmentResult', 'downloadTrackTreatmentResult']),
  computed: mapGetters(['patient', 'trackTreatmentResult'])
})
export default class TrackTreatmentResult extends Vue {
  patient!: Patient
  trackTreatmentResult!: Patient['trackTreatmentResult']

  // Actions
  updateTrackTreatmentResult!: (payload: {
    id: number
    trackTreatmentResult: Patient['trackTreatmentResult']
  }) => Promise<void>

  downloadTrackTreatmentResult!: (alias: string) => Promise<void>

  async created (): Promise<void> {
    const alias = String(this.$route.params.alias)
    try {
      await this.downloadTrackTreatmentResult(alias)
    } catch (err: any) {
      const message = err.response.data.message
      if (message === 'Patient not found.') {
        await Swal.fire({
          icon: 'info',
          title: this.$t('TrackTreatmentResult.DownloadFailedTitle'),
          confirmButtonText: this.$t(
            'TrackTreatmentResult.DownloadFailedConfirmButton'
          )
        })
        this.$router.push({ name: this.$route.meta.next })
      }
    }
  }

  async submit (): Promise<void> {
    try {
      await this.updateTrackTreatmentResult({
        id: this.patient.id,
        trackTreatmentResult: this.trackTreatmentResult
      })

      await Swal.fire({
        title: this.$t('TrackTreatmentResult.UpdateTitle'),
        confirmButtonText: this.$t('TrackTreatmentResult.UpdateConfirmButton')
      })

      this.$router.push({ name: this.$route.meta.next })
    } catch {
      this.$router.push({ name: 'Logout' })
    }
  }
}
